import { tokens } from "../../theme";
import ApiService from "../../services/ApiService.js";
import Header from "../../components/Header.jsx";
import TableComponent from "../../components/TableComponent.jsx";
import ButtonComponent from "../../components/ButtonComponent.jsx";
import TicketForm from "./TicketForm.jsx";

import { Box, useTheme, Typography, CircularProgress } from "@mui/material";
import { useState, useEffect, useContext } from "react";

import { AuthContext } from "../../context/AuthContext";

const TicketSystem = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const apiService = new ApiService(
    process.env.REACT_APP_MACHINE_DATA_BACKEND_API_PORT
  );

  const [machineErrorData, SetMachineErrorData] = useState([]);
  const [tableSelectedData, setTableSelectedData] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [buttonType, setButtonType] = useState("");
  const [lastUpdate, setLastUpdate] = useState("");
  const [isRefreshing, setIsRefreshing] = useState(false);

  const { isLoggedIn, hasPermission } = useContext(AuthContext);

  const columns = [
    {
      field: "Name",
      headerName: "Name",
      align: "center",
      headerAlign: "center",
    },
    {
      field: "MarkAsDone",
      headerName: "Status",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        const status = params.value;
        let color;

        // Define colors based on status
        switch (status) {
          case false:
            color = colors.redAccent[500];
            break;
          case true:
            color = colors.greenAccent[500];
            break;
          default:
            color = colors.grey[400];
            break;
        }

        return (
          <Box>
            <span
              style={{
                display: "inline-block",
                width: "15px",
                height: "15px",
                borderRadius: "50%",
                backgroundColor: color,
                margin: 0,
              }}
            ></span>
            <Typography
              sx={{
                color:
                  params.value === false
                    ? colors.redAccent[500]
                    : colors.greenAccent[500],
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "100%",
              }}
            >
              {params.value}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "ErrorGroupName",
      headerName: "Error Group",
      align: "center",
      headerAlign: "center",
    },
    {
      field: "ErrorCodeDetail",
      headerName: "Error",
      align: "left",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "Request",
      headerName: "Request",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "updateTicket",
      headerName: "Actions",
      align: "center",
      headerAlign: "center",
      flex: 0.5,
      renderCell: (params) => (
        <ButtonComponent
          label="Update"
          onClick={() => handleRowUpdateClick(params.row)}
          disabled={!(isLoggedIn && hasPermission("reporting.updateticket"))}
          type="action"
        />
      ),
    },
  ];

  ////////////////////FUNCTION///////////////////////
  // Fetch data when the component mounts
  useEffect(() => {
    fetchData();

    const interval = setInterval(() => {
      fetchData(); // Refresh data every 5 minutes
    }, 1 * 60 * 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (!dialogOpen) {
      fetchData();
    }
  }, [dialogOpen]);

  const fetchData = async () => {
    setIsRefreshing(true);
    try {
      const apiVersion = process.env.REACT_APP_MACHINE_DATA_BACKEND_API_VERSION;
      var response = await apiService.get(`${apiVersion}\\error-reports`);

      if (
        response &&
        response.data.length > 0 &&
        response.data.some((item) => item !== null)
      ) {
        var transformedData = response.data.map((data, index) => {
          if (data.MarkAsDone === true) {
            data.ErrorGroupName = "";
            data.ErrorCode = 0;
            data.Request = "";
          }
          var errorCodeDetailValue = "";
          if (data.ErrorCode !== 0)
            errorCodeDetailValue = `${data.ErrorCode}-${data.ErrorCodeName}`;

          return {
            ...data,
            ErrorCodeDetail: errorCodeDetailValue,
            id: index + 1,
          };
        });

        transformedData = groupAndSortData(transformedData);
        SetMachineErrorData(transformedData);
        setLastUpdate(
          new Date().toLocaleString("en-US", {
            dateStyle: "short",
            timeStyle: "short",
          })
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsRefreshing(false);
    }
  };

  const tableSelectionChange = (data) => {
    if (data.length !== 0) setTableSelectedData(data[0]);
    else setTableSelectedData(null);
  };

  const handleOpenTicketClick = () => {
    setButtonType("OPEN");
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  function groupAndSortData(data) {
    // Normalize `CreationTime`
    const normalizedData = data.map((item) => {
      if (typeof item.CreationTime === "string") {
        item.CreationTime = new Date(item.CreationTime);
      } else if (item.CreationTime?.$date) {
        item.CreationTime = new Date(item.CreationTime.$date);
      }
      return item;
    });

    // Use a map to store the latest record for each MachineId
    const latestRecords = normalizedData.reduce((acc, item) => {
      const machineId = item.MachineId;

      if (!acc[machineId] || item.CreationTime > acc[machineId].CreationTime) {
        acc[machineId] = item; // Replace with the more recent record
      }

      return acc;
    }, {});

    // Return grouped structure
    return Object.entries(latestRecords)
      .map(([machineId, record]) => ({
        machineId,
        ...record,
      }))
      .filter((record) => !record.MarkAsDone);
  }

  const handleRowUpdateClick = (row) => {
    setTableSelectedData(row); // Set the selected row data
    setButtonType("UPDATE"); // Set the button type to "UPDATE"
    setDialogOpen(true); // Open the dialog
  };

  const handleRefreshClick = () => {
    fetchData();
  };

  return (
    <Box m="20px">
      <Header title="Ticket System" />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mb: "10px",
          }}
        >
          <ButtonComponent
            label="Open Ticket"
            onClick={handleOpenTicketClick}
            disabled={!(isLoggedIn && hasPermission("reporting.createticket"))}
          />
          <ButtonComponent
            label="Refresh"
            onClick={handleRefreshClick}
            disabled={isRefreshing} // Disable when loading
            icon={isRefreshing ? <CircularProgress size={20} /> : null}
            customStyles={{
              ml: "10px",
            }}
          />
          <Typography
            variant="body2"
            color={colors.grey[400]}
            sx={{ ml: "10px" }}
          >
            Last updated: {lastUpdate}
          </Typography>
        </Box>
      </Box>
      <Box>
        <TableComponent
          data={machineErrorData}
          columns={columns}
          onRowSelect={tableSelectionChange}
          showToolbar={false}
          customStyles={{
            height: "500px",
          }}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
        />
      </Box>

      <TicketForm
        dialogOpen={dialogOpen}
        dialogCloseEvent={handleDialogClose}
        data={tableSelectedData ? tableSelectedData : null}
        type={buttonType}
      />
    </Box>
  );
};

export default TicketSystem;

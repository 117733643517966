import Header from "../../components/Header.jsx";
import CalendarComponent from "../../components/CalendarComponent.jsx";
import { tokens } from "../../theme.js";
import ApiService from "../../services/ApiService.js";
import VisionComponent from "./VisionComponent.jsx";
import ButtonComponent from "../../components/ButtonComponent.jsx";

import { Box, useTheme, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import Divider from "@mui/material/Divider";

const DailyOutput = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const visionList = process.env.REACT_APP_VISION_LIST
    ? process.env.REACT_APP_VISION_LIST.split(",")
    : [];
  const sideInfo = process.env.REACT_APP_SIDE_LIST
    ? process.env.REACT_APP_SIDE_LIST.split(",")
    : [];

  const [overallLineData, setOverallLineData] = useState(
    visionList.reduce((acc, key) => ({ ...acc, [key]: null }), {})
  );
  const [failDetailLineData, setFailDetailLineData] = useState(
    visionList.reduce(
      (acc, vision) => ({
        ...acc,
        [vision]: sideInfo.reduce(
          (sideAcc, side) => ({ ...sideAcc, [side]: null }),
          {}
        ),
      }),
      {}
    )
  );
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const apiService = new ApiService();

  // Fetch data for the selected month
  const handleViewClick = () => {
    const fetchAllData = async () => {
      await Promise.all(visionList.map((key) => fetchOverallData(key)));
    };

    fetchAllData();
  };

  // Fetch data when the component mounts
  useEffect(() => {
    const fetchAllData = async () => {
      await Promise.all(visionList.map((key) => fetchOverallData(key)));
    };

    fetchAllData();
  }, []);

  // Function to fetch data from the local API
  const fetchOverallData = async (key) => {
    try {
      const response = await apiService.get("/overallLineTable", {
        date: `${selectedDate.format("YYYY_MM_DD")}`,
        line: `${key}`,
      });

      if (
        response &&
        response.length > 0 &&
        response.some((item) => item !== null)
      ) {
        const dataWithId = response.map((item, index) => ({
          id: index + 1, // Assign a unique id (e.g., index + 1)
          ...item,
        }));

        setOverallLineData((prevData) => ({ ...prevData, [key]: dataWithId }));
      }

      var detailData = { ...failDetailLineData };

      for (const side of sideInfo) {
        const faildetailresponse = await apiService.get("/failDetail", {
          date: `${selectedDate.format("YYYY_MM_DD")}`,
          line: `${key}`,
          station: `${process.env.REACT_APP_MACHINE_NAME}-${key}_${side}`,
        });

        if (
          faildetailresponse &&
          faildetailresponse.length > 0 &&
          faildetailresponse.some((item) => item !== null)
        ) {
          const dataWithId = faildetailresponse.map((item, index) => ({
            id: index + 1, // Assign a unique id (e.g., index + 1)
            ...item,
          }));

          detailData[key][side] = dataWithId;
        }
      }

      setFailDetailLineData(detailData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <Box m="20px">
      <Header title="Daily Output" />

      <Box display="flex" alignItems="center">
        <Typography variant="h5" color={colors.greenAccent[400]}>
          Select Date:
        </Typography>
        <CalendarComponent
          value={selectedDate}
          updateFunction={setSelectedDate}
          customStyles={{
            ml: "10px"
          }}
        />
        <ButtonComponent
          label="View"
          onClick={handleViewClick}
          customStyles={{ ml: "10px" }}
        />
      </Box>
      {visionList.map((vision) => (
        <Box key={vision}>
          <VisionComponent
            vision={vision}
            overallData={overallLineData[vision]}
            detailData={failDetailLineData[vision]}
          />
          <Divider
            variant="middle"
            sx={{
              borderColor: colors.blueAccent[300],
              borderWidth: 2,
              mb: 10,
            }}
          />
        </Box>
      ))}
    </Box>
  );
};

export default DailyOutput;

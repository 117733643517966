// ApiService.js
import axios from "axios";
import qs from "qs";
import { toast } from "react-toastify";
import { getNavigate } from "../context/NavigateContext";
import { getLogoutGlobal } from "../context/AuthContext";

class ApiService {
  constructor(port) {
    const isDevelopment = process.env.NODE_ENV === "development";

    this.port = port || process.env.REACT_APP_BACKEND_API_PORT;
    const baseURL = isDevelopment
      ? `${process.env.REACT_APP_BACKEND_API_URL}:${
          port || process.env.REACT_APP_BACKEND_API_PORT
        }`
      : process.env.REACT_APP_BACKEND_API_URL;

    this.api = axios.create({
      baseURL: baseURL,
      paramsSerializer: (params) => qs.stringify(params, { encode: false }), // No encoding
    });

    this.api.interceptors.request.use((config) => {
      const token = localStorage.getItem("authToken");
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      return config;
    });

    // Add response interceptor to handle expired tokens
    this.api.interceptors.response.use(
      (response) => response,
      async (error) => {
        const errorCode = error.response.data.error.code;
        if (error.response && error.response.status === 401) {
          if (errorCode === "TOKEN_EXPIRED") {
            toast.error(error.response.data.message);

            const navigate = getNavigate();
            const logout = getLogoutGlobal();
            logout(false);
            navigate("/login");
          }
        }

        // Rethrow the error for specific handling elsewhere
        return Promise.reject(error);
      }
    );
  }

  // Method to perform GET request
  async get(endpoint, params = {}) {
    try {
      const response = await this.api.get(endpoint, { params });
      return response.data;
    } catch (error) {
      console.error("GET request failed:", error);
      throw error;
    }
  }

  // Method to perform POST request
  async post(endpoint, data) {
    try {
      const response = await this.api.post(endpoint, data);
      return response.data;
    } catch (error) {
      console.error("POST request failed:", error);
      throw error;
    }
  }

  // Method to perform PUT request
  async put(endpoint, data) {
    try {
      const response = await this.api.put(endpoint, data);
      return response.data;
    } catch (error) {
      console.error("PUT request failed:", error);
      throw error;
    }
  }

  // Method to perform DELETE request
  async delete(endpoint) {
    try {
      const response = await this.api.delete(endpoint);
      return response.data;
    } catch (error) {
      console.error("DELETE request failed:", error);
      throw error;
    }
  }

  // Method to perform PATCH request
  async patch(endpoint, data) {
    try {
      const response = await this.api.patch(endpoint, data);
      return response.data;
    } catch (error) {
      console.error("PATCH request failed:", error);
      throw error;
    }
  }
}

export default ApiService;

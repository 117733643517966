import { tokens } from "../theme";

import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { Box, useTheme, ClickAwayListener } from "@mui/material";
import { useState } from "react";

const CustomToolbar = () => {
  const generateFileName = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");

    return `Data_${year}_${month}_${day}_${hours}${minutes}${seconds}`;
  };

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport
        csvOptions={{
          fileName: generateFileName(),
          utf8WithBom: true,
          allColumns: true,
          allRows: true,
        }}
      />
    </GridToolbarContainer>
  );
};

const TableComponent = ({
  data,
  columns,
  columnsGroup,
  customStyles = {},
  onRowSelect,
  showToolbar = true,
  ...properties
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  const handleClearSelection = () => {
    setRowSelectionModel([]);
    if (onRowSelect) onRowSelect([]);
  };

  return (
    <ClickAwayListener onClickAway={handleClearSelection}>
      <Box
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeader": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiDataGrid-row.Mui-selected": {
            backgroundColor: `${colors.grey[400]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
          ...customStyles,
        }}
      >
        <DataGrid
          rows={data}
          columns={columns}
          columnGroupingModel={columnsGroup}
          rowSelectionModel={rowSelectionModel}
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
            const selectedRows = data.filter((row) =>
              newRowSelectionModel.includes(row.id)
            );

            if (onRowSelect) onRowSelect(selectedRows); // Pass selected rows to the handler
          }}
          slots={
            showToolbar
              ? {
                  toolbar: () => <CustomToolbar />, // Include toolbar
                }
              : undefined
          }
          {...properties}
        />
      </Box>
    </ClickAwayListener>
  );
};

export default TableComponent;

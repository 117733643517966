import { tokens } from "../theme";

import { Box, useTheme, Typography } from "@mui/material";
import { ResponsiveLine } from "@nivo/line";

const LineGraphComponent = ({
  data,
  label,
  customStyles = {
    height: "300px",
    width: "1000px",
  },
  enableStack
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box
      mt="20px"
      mb="20px"
      sx={{
        ...customStyles,
      }}
    >
      <Typography
        variant="h5"
        color={colors.greenAccent[400]}
        textAlign="center"
      >
        {label}
      </Typography>
      {data && data.length > 0 ? (
        <ResponsiveLine
          data={data}
          margin={{ top: 20, right: 30, bottom: 100, left: 30 }}
          xScale={{ type: "point" }}
          yScale={{
            type: "linear",
            min: "auto",
            max: "auto",
            stacked: enableStack,
            reverse: false,
          }}
          yFormat=" >-.2f"
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Hour",
            legendOffset: 36,
            legendPosition: "middle",
            truncateTickAt: 0,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Unit",
            legendOffset: -55,
            legendPosition: "middle",
            truncateTickAt: 0,
          }}
          colors={{ scheme: "set2" }}
          enableGridX={false}
          lineWidth={5}
          pointSize={10}
          pointColor={{ theme: "background" }}
          pointBorderWidth={2}
          pointBorderColor={{ from: "serieColor" }}
          pointLabel="data.yFormatted"
          pointLabelYOffset={-12}
          enableArea={!enableStack}
          areaOpacity={0.5}
          useMesh={true}
          enableSlices="x"
          legends={[
            {
              anchor: "center",
              direction: "row",
              justify: false,
              translateX: 0,
              translateY: 150,
              itemsSpacing: 50,
              itemDirection: "left-to-right",
              itemWidth: 80,
              itemHeight: 20,
              itemOpacity: 0.75,
              symbolSize: 15,
              symbolShape: "circle",
              symbolBorderColor: "rgba(0, 0, 0, .5)",
              effects: [
                {
                  on: "hover",
                  style: {
                    itemBackground: "rgba(0, 0, 0, .03)",
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
          theme={{
            legends: {
              title: {
                text: {
                  fontSize: 11,
                  fill: `${colors.grey[100]}`,
                  outlineWidth: 0,
                  outlineColor: "transparent",
                },
              },
              text: {
                fontSize: 11,
                fill: `${colors.grey[100]}`,
                outlineWidth: 0,
                outlineColor: "transparent",
              },
              ticks: {
                line: {},
                text: {
                  fontSize: 10,
                  fill: `${colors.grey[100]}`,
                  outlineWidth: 0,
                  outlineColor: "transparent",
                },
              },
            },
            tooltip: {
              container: {
                color: `${colors.grey[300]}`,
              },
            },
            axis: {
              ticks: {
                text: {
                  fill: `${colors.grey[300]}`, // Customize axis text color here (e.g., red)
                },
              },
              legend: {
                text: {
                  fill: `${colors.grey[300]}`, // Customize legend text color here (e.g., red)
                },
              },
            },
          }}
        />
      ) : (
        <Typography variant="h6" color={colors.grey[100]} textAlign="center">
          No Data
        </Typography>
      )}
    </Box>
  );
};

export default LineGraphComponent;

import { tokens } from "../theme";

import { useTheme } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const CalendarComponent = ({
  type,
  value,
  updateFunction,
  customStyles = {},
  label,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        onChange={
          updateFunction ? (newValue) => updateFunction(newValue) : null
        }
        views={type}
        value={value}
        sx={{
          backgroundColor: colors.grey[800],
          ...customStyles,
        }}
        label={label}
      />
    </LocalizationProvider>
  );
};

export default CalendarComponent;

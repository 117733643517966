import { tokens } from "../../theme";
import { AuthContext } from "../../context/AuthContext";
import { InputAdornment, IconButton } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { toast } from "react-toastify";

import {
  Box,
  useTheme,
  Button,
  TextField,
  Typography,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const { user, login } = useContext(AuthContext);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(""); // Clear previous errors

    try {
      const loggedInUser = await login(formData.username, formData.password);
      toast.success(`Login successful! Welcome ${loggedInUser.name}`);
      navigate("/");
    } catch (err) {
      console.log(err.message);
      setError(err.message || "Login failed. Please try again.");
      toast.error("Login failed. Please try again.");
    }
  };

  const handleSignupNavigation = () => {
    navigate("/signup");
  };

  const handleResetPasswordNavigation = () => {
    navigate("/resetPassword");
  };

  return (
    <Box m="20px">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="50%"
        px={2}
      >
        <Typography variant="h4" gutterBottom color={colors.greenAccent[400]}>
          Login
        </Typography>
        <form
          onSubmit={handleSubmit}
          style={{ width: "100%", maxWidth: "400px" }}
        >
          <FormControl
            fullWidth
            margin="normal"
            error={!!error} // Error state for the entire form
          >
            <TextField
              label="Username"
              name="username"
              value={formData.username}
              onChange={handleChange}
              required
            />
            <FormHelperText>{error}</FormHelperText>
          </FormControl>

          <FormControl
            fullWidth
            margin="normal"
            error={!!error} // Error state for password field
          >
            <TextField
              label="Password"
              name="password"
              type={showPassword ? "text" : "password"}
              value={formData.password}
              onChange={handleChange}
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <FormHelperText>{error}</FormHelperText>
          </FormControl>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              padding: "10px 20px",
              mt: "10px",
            }}
          >
            Login
          </Button>
        </form>
        <Typography
          variant="body5"
          sx={{
            mt: "20px",
            cursor: "pointer",
            color: colors.greenAccent[400],
            "&:hover": { textDecoration: "underline" },
          }}
          onClick={handleSignupNavigation}
        >
          Don't have an account? Sign up here
        </Typography>

        <Typography
          variant="body2"
          sx={{
            mt: "10px",
            cursor: "pointer",
            color: colors.blueAccent[400],
            "&:hover": { textDecoration: "underline" },
          }}
          onClick={handleResetPasswordNavigation}
        >
          Forgot your password? Reset it here
        </Typography>
      </Box>
    </Box>
  );
};

export default Login;

import { tokens } from "../theme";

import { useTheme, Select, Box, Typography, MenuItem } from "@mui/material";

const SelectComponent = ({
  label = "Select Option",
  value,
  listData,
  updateFunction,
  message,
  customStyles = {}
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box display="flex" alignItems="center" sx={{
      ...customStyles
    }}>
      <Typography variant="h5" color={colors.greenAccent[400]}>
        {message}
      </Typography>
      <Select
        labelId="dropdown-label"
        value={value}
        onChange={(newValue) => updateFunction(newValue)}
        sx={{
          backgroundColor: colors.grey[800],
          color: colors.grey[100],
          fontSize: "14px",
          ml: "10px",   
        }}
      >
        {listData.map((item, index) => (
          <MenuItem
            key={index}
            value={item}
            sx={{
              color: colors.grey[100],
              fontSize: "14px",
              padding: "10px 20px",
            }}
          >
            {item}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default SelectComponent;

import { tokens } from "../../theme.js";
import Header from "../../components/Header.jsx";
import TableComponent from "../../components/TableComponent.jsx";
import ApiService from "../../services/ApiService.js";
import ButtonComponent from "../../components/ButtonComponent.jsx";

import { Box, useTheme, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const visionList = process.env.REACT_APP_VISION_LIST
    ? process.env.REACT_APP_VISION_LIST.split(",")
    : [];
  const [overallLineData, setOverallLineData] = useState(
    visionList.reduce((acc, key) => ({ ...acc, [key]: null }), {})
  );

  const apiService = new ApiService();

  // Fetch data when the component mounts
  useEffect(() => {
    fetchData();
  }, []);

  // Function to fetch data from the local API
  const fetchData = async () => {
    visionList.map(async (vision) => {
      try {
        const response = await apiService.get("/lineSummaryTable", {
          date: `${selectedDate.format("YYYY_MM_DD")}`,
          line: `${vision}`,
        });

        if (
          response &&
          response.length > 0 &&
          response.some((item) => item !== null)
        ) {
          const dataWithId = response.map((item, index) => ({
            id: index + 1, // Assign a unique id (e.g., index + 1)
            ...item,
          }));

          setOverallLineData((prevData) => ({
            ...prevData,
            [vision]: dataWithId,
          }));
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    });
  };

  const columns = [
    {
      field: "AOI",
      headerName: "AOI",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "Total",
      headerName: "Total",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "Pass",
      headerName: "Pass",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "Pass(%)",
      headerName: "Pass(%)",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "Fail",
      headerName: "Fail",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "Fail(%)",
      headerName: "Fail(%)",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "NA",
      headerName: "NA",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "NA(%)",
      headerName: "NA(%)",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
  ];

  return (
    <Box m="20px">
      <Box sx={{
        display: "flex-wrap",
      }}>
        <Header title="Dashboard" subtitle="Overall performance" />
        <ButtonComponent
          label="Refresh"
          onClick={() => {
            fetchData();
          }}
          ml="0px"
        />
      </Box>
      <Box
        sx={{
          mt: "10px",
        }}
      >
        {visionList.map((vision) => (
          <Box
            sx={{
              mb: "50px",
            }}
          >
            <Typography variant="h5" color={colors.greenAccent[400]} mb="10px">
              {vision}
            </Typography>
            <TableComponent
              data={overallLineData["F01AU"]}
              columns={columns}
              customStyles={{
                height: "55vh",
              }}
              hideFooter={true}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Dashboard;

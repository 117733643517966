import React, { useState, useContext } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  FormControl,
  FormHelperText,
  Divider,
  useTheme,
} from "@mui/material";
import { tokens } from "../../theme";
import { AuthContext } from "../../context/AuthContext";
import { toast } from "react-toastify";
import ApiService from "../../services/ApiService";

const UserProfile = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { user, setUser } = useContext(AuthContext); // Access user data and update function
  const apiService = new ApiService(
    process.env.REACT_APP_USER_AUTH_BACKEND_API_PORT
  );

  // State for profile updates
  const [profileData, setProfileData] = useState({
    name: user?.name || "",
    email: user?.email || "",
  });

  const [passwordData, setPasswordData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [profileError, setProfileError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  // Handle changes for profile inputs
  const handleProfileChange = (e) => {
    const { name, value } = e.target;
    setProfileData({ ...profileData, [name]: value });
  };

  // Handle changes for password inputs
  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswordData({ ...passwordData, [name]: value });
  };

  // Update Profile Function
  const updateProfile = async (e) => {
    e.preventDefault();
    setProfileError("");
    try {
      const apiVersion = process.env.REACT_APP_USER_AUTH_BACKEND_API_VERSION;
      const updatedUser = await apiService.patch(
        `${apiVersion}\\users\\updateMe`,
        profileData
      );
      setUser(updatedUser.data.user); // Update the AuthContext
      toast.success("Profile updated successfully!");
    } catch (err) {
      setProfileError(
        err.response?.data?.message || "Failed to update profile."
      );
      toast.error("Failed to update profile.");
    }
  };

  // Change Password Function
  const changePassword = async (e) => {
    e.preventDefault();
    setPasswordError("");

    if (passwordData.newPassword !== passwordData.confirmPassword) {
      setPasswordError("Passwords do not match.");
      return;
    }

    try {
      const apiVersion = process.env.REACT_APP_USER_AUTH_BACKEND_API_VERSION;
      const updatedUser = await apiService.patch(
        `${apiVersion}\\users\\updateMyPassword`,
        {
          passwordCurrent: passwordData.currentPassword,
          password: passwordData.newPassword,
          passwordConfirm: passwordData.confirmPassword,
        }
      );
      toast.success("Password updated successfully!");
    } catch (err) {
      setPasswordError(
        err.response?.data?.message || "Failed to update password."
      );
      toast.error("Failed to update password.");
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="top"
      height="100vh"
      px={2}
    >
      <Box width="100%" maxWidth="500px">
        <Typography
          variant="h4"
          gutterBottom
          color={colors.greenAccent[400]}
          textAlign="center"
        >
          User Profile
        </Typography>

        {/* Profile Update Section */}
        <Box component="form" onSubmit={updateProfile} mt={3}>
          <Typography variant="h6" gutterBottom textAlign="center">
            Update Profile
          </Typography>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Name"
              name="name"
              value={profileData.name}
              onChange={handleProfileChange}
              required
            />
          </FormControl>
          {profileError && (
            <FormHelperText error>{profileError}</FormHelperText>
          )}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 2, backgroundColor: colors.blueAccent[700] }}
          >
            Save Changes
          </Button>
        </Box>

        <Divider sx={{ my: 4 }} />

        {/* Password Change Section */}
        <Box component="form" onSubmit={changePassword}>
          <Typography variant="h6" gutterBottom textAlign="center">
            Change Password
          </Typography>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Current Password"
              name="currentPassword"
              type="password"
              value={passwordData.currentPassword}
              onChange={handlePasswordChange}
              required
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField
              label="New Password"
              name="newPassword"
              type="password"
              value={passwordData.newPassword}
              onChange={handlePasswordChange}
              required
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Confirm Password"
              name="confirmPassword"
              type="password"
              value={passwordData.confirmPassword}
              onChange={handlePasswordChange}
              required
            />
          </FormControl>
          {passwordError && (
            <FormHelperText error>{passwordError}</FormHelperText>
          )}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 2, backgroundColor: colors.blueAccent[700] }}
          >
            Change Password
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default UserProfile;

import { tokens } from "../theme";

import { useTheme, Button, Card, CardContent, Typography } from "@mui/material";

const CardComponent = ({
  title,
  children,
  onClick,
  btnLabel,
  customStyles = {},
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Card sx={{ maxWidth: 345, ...customStyles }}>
      <CardContent>
        <Typography
          gutterBottom
          variant="h5"
          color={colors.primary[100]}
          fontWeight="bold"
          sx={{
            borderBottom: "2px solid",
            borderColor: colors.greenAccent[400],
          }}
        >
          {title}
        </Typography>
        {children}
      </CardContent>
      {onClick && (
        <Button
          size="small"
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.grey[100],
            fontSize: "14px",
            padding: "10px 20px",
          }}
        >
          {btnLabel}
        </Button>
      )}
    </Card>
  );
};

export default CardComponent;

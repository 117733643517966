import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  Switch,
  useTheme,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { tokens } from "../../theme";
import ApiService from "../../services/ApiService";
import TableComponent from "../../components/TableComponent";
import ButtonComponent from "../../components/ButtonComponent";

import { toast } from "react-toastify";
import { AuthContext } from "../../context/AuthContext";

const UserAccount = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [users, setUsers] = useState([]);
  const [groups, setGroups] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [loading, setLoading] = useState(true);
  const { user: loggedInUser } = useContext(AuthContext);

  const apiService = new ApiService(
    process.env.REACT_APP_USER_AUTH_BACKEND_API_PORT
  );

  // Fetch users and groups
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const userResponse = await apiService.get(
          `${process.env.REACT_APP_USER_AUTH_BACKEND_API_VERSION}/users`
        );
        const groupResponse = await apiService.get(
          `${process.env.REACT_APP_USER_AUTH_BACKEND_API_VERSION}/user-group`
        );
        setUsers(userResponse.data || []);
        setGroups(groupResponse.data || []);
      } catch (error) {
        console.error("Failed to fetch data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Handle status toggle
  const handleStatusToggle = async (userId, isActive) => {
    if (userId === loggedInUser.id) {
      toast.warning("You cannot change your own status.");
      return;
    }

    try {
      const res = await apiService.patch(
        `${process.env.REACT_APP_USER_AUTH_BACKEND_API_VERSION}/users/${userId}`,
        { active: !isActive }
      );

      if (res.status === "success") {
        toast.success(`User status updated.`);
      } else {
        toast.error(`Failed to update user status!`);
      }

      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user.id === userId ? { ...user, active: !isActive } : user
        )
      );
    } catch (error) {
      toast.error(`Failed to update user status!`);
    }
  };

  // Open edit modal
  const handleEditClick = (user) => {
    if (user.id === loggedInUser.id) {
      toast.warning("You cannot edit your own account.");
      return;
    }
    setSelectedUser(user);
    setShowEditModal(true);
  };

  // Save user group changes
  const handleSaveUserGroup = async () => {
    try {
      const res = await apiService.patch(
        `${process.env.REACT_APP_USER_AUTH_BACKEND_API_VERSION}/users/${selectedUser.id}`,
        { group_id: selectedUser.group_id }
      );

      if (res.status === "success") {
        toast.success(`User group updated.`);
      } else {
        toast.error(`Failed to update user group!`);
      }

      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user.id === selectedUser.id
            ? { ...user, group_id: selectedUser.group_id }
            : user
        )
      );
      setShowEditModal(false);
    } catch (error) {
      toast.error(`Failed to update user group!`);
    }
  };

  // Open delete dialog
  const handleDeleteClick = (user) => {
    if (user.id === loggedInUser.id) {
      toast.warning("You cannot delete your own account.");
      return;
    }
    setSelectedUser(user);
    setShowDeleteDialog(true);
  };

  // Confirm delete user
  const handleDeleteUser = async () => {
    try {
      const res = await apiService.delete(
        `${process.env.REACT_APP_USER_AUTH_BACKEND_API_VERSION}/users/${selectedUser.id}`
      );

      if (res.status === "success") {
        toast.success(`User had been deleted.`);
      } else {
        toast.error(`Failed to delete user!`);
      }

      setUsers((prevUsers) =>
        prevUsers.filter((user) => user.id !== selectedUser.id)
      );
      setShowDeleteDialog(false);
    } catch (error) {
      toast.error(`Failed to delete user!`);
    }
  };

  // Columns for the DataGrid
  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1.5,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "group_id",
      headerName: "Group",
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) =>
        groups.find((group) => group.id === params)?.name || "Unknown",
    },
    {
      field: "active",
      headerName: "Status",
      flex: 0.5,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <Switch
          checked={params.row.active}
          onChange={() => handleStatusToggle(params.row.id, params.row.active)}
          sx={{
            "& .MuiSwitch-switchBase.Mui-checked": {
              color: theme.palette.success.main, // Change the color when checked
              "&:hover": {
                backgroundColor: theme.palette.success.light, // Change the hover background
              },
            },
            "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
              backgroundColor: theme.palette.success.main, // Change the track color when checked
            },
            "& .MuiSwitch-track": {
              backgroundColor: theme.palette.grey[400], // Default track color
            },
          }}
        />
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      align: "center",
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => (
        <>
          <Button
            onClick={() => handleEditClick(params.row)}
            variant="outlined"
            startIcon={<EditIcon />}
            size="small"
            sx={{
              mr: 1,
            }}
            color="secondary"
          >
            Edit
          </Button>
          <Button
            onClick={() => handleDeleteClick(params.row)}
            variant="outlined"
            color="error"
            startIcon={<DeleteIcon />}
            size="small"
          >
            Delete
          </Button>
        </>
      ),
    },
  ];

  return (
    <Box>
      <Typography variant="h5" mb={3} color={colors.greenAccent[400]}>
        User Management
      </Typography>
      <TableComponent
        data={users}
        columns={columns}
        pageSize={10}
        loading={loading}
        checkboxSelection={false}
        showToolbar={false}
        customStyles={{
          height: "500px",
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
      />
      {/* Edit User Modal */}
      <Dialog open={showEditModal} onClose={() => setShowEditModal(false)}>
        <DialogTitle>Edit User Group</DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <InputLabel id="group-select-label">Select Group</InputLabel>
            <Select
              labelId="group-select-label"
              value={selectedUser?.group_id || ""}
              onChange={(e) =>
                setSelectedUser({ ...selectedUser, group_id: e.target.value })
              }
              sx={{
                width: "100%",
              }}
            >
              {groups.map((group) => (
                <MenuItem key={group.id} value={group.id}>
                  {group.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <ButtonComponent
            label="Cancel"
            onClick={() => setShowEditModal(false)}
            customStyles={{
              mb: "20px",
            }}
          />
          <ButtonComponent
            label="Save"
            onClick={handleSaveUserGroup}
            customStyles={{
              mb: "20px",
            }}
          />
        </DialogActions>
      </Dialog>
      {/* Delete User Confirmation */}
      <Dialog
        open={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete <strong>{selectedUser?.name}</strong>?
        </DialogContent>
        <DialogActions>
          <ButtonComponent
            label="Cancel"
            onClick={() => setShowDeleteDialog(false)}
            customStyles={{
              mb: "20px",
            }}
          />
          <ButtonComponent
            label="Delete"
            onClick={handleDeleteUser}
            customStyles={{
              mb: "20px",
            }}
            type="warning"
          />
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default UserAccount;

import { tokens } from "../../theme.js";
import ApiService from "../../services/ApiService.js";
import Header from "../../components/Header.jsx";
import CardComponent from "../../components/CardComponent.jsx";

import { Box, useTheme, Typography } from "@mui/material";
import { useState, useEffect } from "react";

const MachineDetail = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const apiService = new ApiService(
    process.env.REACT_APP_MACHINE_DATA_BACKEND_API_PORT
  );

  const [iotData, setIotData] = useState([]);

  ////////////////////FUNCTION///////////////////////
  // Fetch data when the component mounts
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const apiVersion = process.env.REACT_APP_MACHINE_DATA_BACKEND_API_VERSION;
      var response = await apiService.get(`${apiVersion}\\iot-data`);

      if (
        response &&
        response.data.length > 0 &&
        response.data.some((item) => item !== null)
      ) {
        setIotData(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getTemperatureStatus = (value, max, min) => {
    return value >= max || value < min;
  };

  return (
    <Box m="20px">
      <Header title="Machine Detail" />
      <Box
        sx={{
          mt: "10px",
          display: "flex",
          width: "100%",
          flexWrap: "wrap",
          gap: "15px 15px",
          justifyContent: "space-around",
          paddingBottom: "20px",
        }}
      >
        {iotData.map((data) => (
          <Box key={data._id}>
            <CardComponent
              title={data.Name}
              customStyles={{
                width: "50vh",
                backgroundColor: colors.primary[400],
              }}
            >
              <Box display="flex" justifyContent="space-between">
                <Typography
                  gutterBottom
                  variant="h6"
                  component="div"
                  color={colors.primary[100]}
                  width="auto"
                >
                  Core
                </Typography>
                <Typography
                  gutterBottom
                  variant="h6"
                  component="div"
                  color={
                    getTemperatureStatus(
                      data.TemperatureA,
                      data.TemperatureAMax,
                      data.TemperatureAMin
                    )
                      ? colors.redAccent[400]
                      : colors.greenAccent[400]
                  }
                  fontWeight="bold"
                  width="auto"
                >
                  {data.TemperatureA}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <Typography
                  gutterBottom
                  variant="h6"
                  component="div"
                  color={colors.primary[100]}
                  width="auto"
                >
                  Cavity
                </Typography>
                <Typography
                  gutterBottom
                  variant="h6"
                  component="div"
                  color={
                    getTemperatureStatus(
                      data.TemperatureB,
                      data.TemperatureBMax,
                      data.TemperatureBMin
                    )
                      ? colors.redAccent[400]
                      : colors.greenAccent[400]
                  }
                  fontWeight="bold"
                  width="auto"
                >
                  {data.TemperatureB}
                </Typography>
              </Box>
            </CardComponent>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default MachineDetail;

import React, { createContext, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";

const NavigateContext = createContext();

let navigateRef = null; // A global variable to store the `navigate` function

export const NavigationProvider = ({ children }) => {
  const navigate = useNavigate();

  // Store `navigate` in the global ref
  navigateRef = navigate;

  return (
    <NavigateContext.Provider value={navigate}>
      {children}
    </NavigateContext.Provider>
  );
};

// Custom hook to use navigation inside React components
export const useNavigation = () => useContext(NavigateContext);

// Function to access `navigate` globally outside of React components
export const getNavigate = () => {
  if (!navigateRef) {
    throw new Error("NavigateContext has not been initialized yet.");
  }
  return navigateRef;
};

import { tokens } from "../../theme";
import Header from "../../components/Header";

import { Box, useTheme, Tabs, Tab } from "@mui/material";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import React, { useContext } from "react";

import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";

import { AuthContext } from "../../context/AuthContext";

const Settings = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const location = useLocation();

  const { isLoggedIn, hasPermission } = useContext(AuthContext);

  const tabsConfig = [
    {
      route: "/settings/access-rights",
      label: "Access Rights",
      icon: <PersonAddOutlinedIcon />,
      permission: "settings.userright",
    },
    {
      route: "/settings/user-account",
      label: "Manage User",
      icon: <ManageAccountsIcon />,
      permission: "settings.usermanagement",
    },
  ];

  const accessibleTabs = isLoggedIn
    ? tabsConfig.filter((tab) => hasPermission(tab.permission))
    : [];

  const currentTab = accessibleTabs.findIndex((tab) =>
    location.pathname.startsWith(tab.route)
  );

  const handleTabChange = (event, newValue) => {
    if (newValue >= 0 && newValue < accessibleTabs.length) {
      navigate(accessibleTabs[newValue].route);
    } else {
      console.error("Invalid tab index:", newValue);
    }
  };

  return (
    <Box m="20px" height="85%">
      <Header title="Settings" />
      <Box
        display="flex"
        height="85%"
        sx={{
          mt: "20px",
        }}
      >
        <Tabs
          orientation="vertical"
          value={currentTab >= 0 ? currentTab : false}
          onChange={handleTabChange}
          sx={{
            borderRight: 1,
            borderColor: "divider",
            minWidth: 200,
            "& .MuiTab-root": {
              justifyContent: "flex-between",
              textTransform: "none",
              color: "text.secondary",
              transition: "color 0.3s ease",
              "&:hover": {
                color: "#868dfb !important",
              },
            },
            "& .Mui-selected": {
              color: "#6870fa !important",
            },
            "& .MuiTabs-indicator": {
              left: 0,
            },
          }}
        >
          {accessibleTabs.map((tab, index) => (
            <Tab
              key={tab.route}
              label={tab.label}
              icon={tab.icon}
              iconPosition="start"
            />
          ))}
        </Tabs>

        {/* Dynamic Content */}
        <Box flexGrow={1} p={3}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default Settings;

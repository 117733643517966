import { tokens } from "../../theme.js";
import ApiService from "../../services/ApiService.js";
import ButtonComponent from "../../components/ButtonComponent.jsx";
import CalendarComponent from "../../components/CalendarComponent.jsx";
import Header from "../../components/Header.jsx";
import SelectComponent from "../../components/SelectComponent.jsx";
import LineGraphComponent from "../../components/LineGraphComponent.jsx";

import { Box, useTheme, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useState, useEffect } from "react";
import TableComponent from "../../components/TableComponent.jsx";

function transformOverallDataForNivoLineChart(data) {
  const seriesKeys = ["OverallPass", "OverallFail"];
  var newData = [];
  if (data && data.length > 0) {
    // Map each key to a format Nivo requires, with id and data array.
    newData = seriesKeys.map((key) => ({
      id: key,
      data: data.map((item) => ({
        x: item.FiveMinutes,
        y: item[key],
      })),
    }));
  } else newData = data;

  return newData;
}

function transformDetailDataForNivoLine(data) {
  // Define the keys we want to transform into separate lines
  const keys = ["Fail", "NoFormer", "NoGlove", "FormerRotate"];
  var transformedData = [];
  if (data && data.length > 0) {
    // Initialize an empty array to store each line's data
    transformedData = keys.map((key) => ({
      id: key,
      data: data.map((item) => ({
        x: item.FiveMinutes, // x-axis value (hour)
        y: item[key], // y-axis value for the specific key
      })),
    }));
  } else transformedData = data;

  return transformedData;
}

const FiveMinutesOutput = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const apiService = new ApiService();

  const visionList = process.env.REACT_APP_VISION_LIST
    ? process.env.REACT_APP_VISION_LIST.split(",")
    : [];
  const sideInfo = process.env.REACT_APP_SIDE_LIST
    ? process.env.REACT_APP_SIDE_LIST.split(",")
    : [];
  const cameraList = visionList.flatMap((vision) =>
    sideInfo.map(
      (side) => `${process.env.REACT_APP_MACHINE_NAME}-${vision}_${side}`
    )
  );

  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [selectedCamera, setSelectedCamera] = useState(cameraList[0]);

  const [detailData, setDetailData] = useState([]);
  const [overallData, setOverallData] = useState([]);

  ////////////////////FUNCTION///////////////////////
  // Fetch data when the component mounts
  useEffect(() => {
    fetchAllData();
  }, []);

  const handleViewClick = () => {
    fetchAllData();
  };

  // Function to fetch data from the local API
  const fetchAllData = async (key) => {
    try {
      var response = await apiService.get("/fiveMinutesDetailData", {
        date: `${selectedDate.format("YYYY_MM_DD")}`,
        line: extractVisionName(selectedCamera),
        visionName: selectedCamera,
      });

      if (
        response &&
        response.length > 0 &&
        response.some((item) => item !== null)
      ) {
        const dataWithId = response.map((item, index) => ({
          id: index + 1, // Assign a unique id (e.g., index + 1)
          ...item,
        }));

        setDetailData(dataWithId);
      }

      var overallData = { ...overallData };

      const overallresponse = await apiService.get("/fiveMinutesData", {
        date: `${selectedDate.format("YYYY_MM_DD")}`,
        line: extractVisionName(selectedCamera),
        visionName: selectedCamera,
      });

      if (
        overallresponse &&
        overallresponse.length > 0 &&
        overallresponse.some((item) => item !== null)
      ) {
        const dataWithId = overallresponse.map((item, index) => ({
          id: index + 1, // Assign a unique id (e.g., index + 1)
          ...item,
        }));

        setOverallData(dataWithId);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  function extractVisionName(item) {
    // Use regular expression to capture the vision part between "-" and "_"
    const match = item.match(/-(.*?)_/);
    return match ? match[1] : null;
  }

  const handleSelectionChange = (event) => {
    setSelectedCamera(event.target.value);
  };

  return (
    <Box m="20px">
      <Header title="Five Minutes Output" />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <SelectComponent
          value={selectedCamera}
          listData={cameraList}
          label="Select Camear"
          message="Select Camera:"
          updateFunction={handleSelectionChange}
        />
        <Typography variant="h5" color={colors.greenAccent[400]} ml="10px">
          Select Date:
        </Typography>
        <CalendarComponent
          value={selectedDate}
          customStyles={{ ml: "10px" }}
          updateFunction={setSelectedDate}
        />
        <ButtonComponent
          label="View"
          onClick={handleViewClick}
          customStyles={{ ml: "10px" }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: "10px",
        }}
      >
        <LineGraphComponent
          data={transformOverallDataForNivoLineChart(overallData)}
          label={"Five Minutes Overall"}
          customStyles={{
            height: "300px",
            width: "100%",
          }}
        />
      </Box>
      <Box
        sx={{
          mt: "10px",
          justifyContent: "center",
        }}
      >
        <LineGraphComponent
          data={transformOverallDataForNivoLineChart(overallData)}
          label={"Five Minutes Overall Stack"}
          customStyles={{
            height: "300px",
            width: "100%",
          }}
          enableStack={true}
        />
      </Box>
      <Box
        sx={{
          mt: "10px",
          justifyContent: "center",
        }}
      >
        <LineGraphComponent
          data={transformDetailDataForNivoLine(detailData)}
          label={"Five Minutes Fail Details"}
          customStyles={{
            height: "300px",
            width: "100%",
          }}
          enableStack={false}
        />
      </Box>
    </Box>
  );
};

export default FiveMinutesOutput;

import { tokens } from "../../theme";
import TableComponent from "../../components/TableComponent";
import PieChartComponent from "../../components/PieChartComponent";

import { Box, useTheme, Typography } from "@mui/material";

const VisionComponent = ({ vision, overallData, detailData }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const sideInfo = ["Top", "Bottom", "Front"];

  const columns = [
    {
      field: "AOI",
      headerName: "Station",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "Total",
      headerName: "Total",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "Pass",
      headerName: "Pass",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "Pass(%)",
      headerName: "Pass(%)",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "Fail",
      headerName: "Fail",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "Fail(%)",
      headerName: "Fail(%)",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "NoFormer",
      headerName: "NoFormer",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "NoFormer(%)",
      headerName: "NoFormer(%)",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "NoGlove",
      headerName: "NoGlove",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "NoGlove(%)",
      headerName: "NoGlove(%)",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "FormerRotate",
      headerName: "FormerRotate",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "FormerRotate(%)",
      headerName: "FormerRotate(%)",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
  ];

  return (
    <Box mt="10px">
      <Box>
        <Typography variant="h5" color={colors.greenAccent[400]}>
          {vision}
        </Typography>
      </Box>
      <Box m="5px 0 0 0">
        <TableComponent
          data={overallData}
          columns={columns}
          customStyles={{
            height: "26vh"
          }}
          hideFooter={true}
        />
      </Box>
      <Box display="flex" justifyContent="center">
        {sideInfo.map((side) => (
            <PieChartComponent key={side} data={detailData[side]} label={`${process.env.REACT_APP_MACHINE_NAME}-${vision}_${side}`}/>
        ))}
      </Box>
    </Box>
  );
};

export default VisionComponent;

import React, { useState, useEffect } from "react";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import { useNavigate, useLocation } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";

import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import SpaceDashboardOutlinedIcon from "@mui/icons-material/SpaceDashboardOutlined";
import SpeedOutlinedIcon from "@mui/icons-material/SpeedOutlined";
import BrokenImageOutlinedIcon from "@mui/icons-material/BrokenImageOutlined";
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import TvOutlinedIcon from "@mui/icons-material/TvOutlined";
import Looks5OutlinedIcon from "@mui/icons-material/Looks5Outlined";
import TodayOutlinedIcon from "@mui/icons-material/TodayOutlined";
import LooksOneOutlinedIcon from "@mui/icons-material/LooksOneOutlined";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import PrecisionManufacturingOutlinedIcon from "@mui/icons-material/PrecisionManufacturingOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Tooltip title={title}>
      <MenuItem
        active={selected.includes(to)}
        style={{
          color: colors.grey[100],
        }}
        onClick={() => setSelected(title)}
        icon={icon}
      >
        <Typography>{title}</Typography>
        <Link to={to} />
      </MenuItem>
    </Tooltip>
  );
};

const Sidebar = ({ setIsSidebar }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");
  const machineName = `${process.env.REACT_APP_MACHINE_NAME}${process.env.REACT_APP_MACHINE_ID}`;

  const visionList = process.env.REACT_APP_VISION_LIST
    ? process.env.REACT_APP_VISION_LIST.split(",")
    : [];

  //Menu hide or show status
  const showDashbaord = process.env.REACT_APP_SHOW_DASHBOARD === "true";
  const showVisionModule = process.env.REACT_APP_SHOW_VISION === "true";
  const showImageViewer = process.env.REACT_APP_SHOW_IMAGE_VIEWER === "true";
  const showRejectStation =
    process.env.REACT_APP_SHOW_REJECT_STATION === "true";
  const showUptime = process.env.REACT_APP_SHOW_UPTIME === "true";
  const showOutput = process.env.REACT_APP_SHOW_OUTPUT === "true";
  const showReporting = process.env.REACT_APP_SHOW_REPORTING === "true";
  const showMachineDetail =
    process.env.REACT_APP_SHOW_MACHINE_DETAIL === "true";
  const showMachineStatus =
    process.env.REACT_APP_SHOW_MACHINE_STATUS === "true";

  //Submenu hide or show status
  const showOutput_FiveMinutes =
    process.env.REACT_APP_SHOW_OUTPUT_FIVEMINUTES === "true";
  const showOutput_Hourly = process.env.REACT_APP_SHOW_OUTPUT_HOURLY === "true";
  const showOutput_Daily = process.env.REACT_APP_SHOW_OUTPUT_DAILY === "true";

  const location = useLocation();

  useEffect(() => {
    setSelected(location.pathname);
  }, [location]);

  const handleToggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
    setIsSidebar(!isCollapsed); // Toggle the sidebar visibility
  };

  return (
    <Box
      sx={{
        position: "fixed", // Fix the sidebar position
        height: "100%",
        transition: "width 0.3s ease", // Transition for width change
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
        "& .pro-menu-item": {
          mt: "10px",
          mb: "10px",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          <MenuItem
            onClick={handleToggleSidebar}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <img
                  alt="profile-user"
                  width="125px"
                  height="25px"
                  src={`../../assets/Logo.png`}
                  style={{ cursor: "default" }}
                />
                <IconButton onClick={handleToggleSidebar}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
            {!isCollapsed && (
              <Box mb="25px">
                <Box textAlign="center">
                  <Typography
                    variant="h2"
                    color={colors.grey[100]}
                    fontWeight="bold"
                    sx={{ m: "10px 0 0 0" }}
                  >
                    {machineName}
                  </Typography>
                </Box>
              </Box>
            )}
          </MenuItem>

          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            {showDashbaord && (
              <Item
                title="Dashboard"
                to="/"
                icon={<SpaceDashboardOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            )}
            {showVisionModule && (
              <>
                <Typography
                  variant="h6"
                  color={colors.grey[300]}
                  sx={{ m: "15px 0 5px 20px" }}
                >
                  Vision
                </Typography>
                {visionList.map((vision) => (
                  <Item
                    key={vision}
                    title={vision}
                    to={`/${vision}`}
                    icon={<SpeedOutlinedIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                ))}
              </>
            )}

            {showImageViewer && (
              <>
                <Typography
                  variant="h6"
                  color={colors.grey[300]}
                  sx={{ m: "15px 0 5px 20px" }}
                >
                  Data
                </Typography>
                <Item
                  title="Image Viewer"
                  to="/imageviewer"
                  icon={<BrokenImageOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </>
            )}

            {showRejectStation && (
              <Item
                title="Reject Station"
                to="/rejectstation"
                icon={<TvOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            )}

            {showUptime && (
              <Item
                title="Up Time"
                to="/uptime"
                icon={<HistoryOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            )}

            {showOutput && (
              <SubMenu
                icon={<TimelineOutlinedIcon />}
                title="Output"
                style={{
                  color: colors.grey[100],
                }}
              >
                {showOutput_FiveMinutes && (
                  <Item
                    title="5 Minutes"
                    to="/fiveMinutesOutput"
                    selected={selected}
                    setSelected={setSelected}
                    icon={<Looks5OutlinedIcon />}
                  />
                )}
                {showOutput_Hourly && (
                  <Item
                    title="Hourly"
                    to="/hourlyOutput"
                    selected={selected}
                    setSelected={setSelected}
                    icon={<LooksOneOutlinedIcon />}
                  />
                )}
                {showOutput_Daily && (
                  <Item
                    title="Daily"
                    to="/dailyOutput"
                    selected={selected}
                    setSelected={setSelected}
                    icon={<TodayOutlinedIcon />}
                  />
                )}
              </SubMenu>
            )}
            {showMachineStatus && (
              <Item
                title="Machine Status"
                to="/machineStatus"
                icon={<PrecisionManufacturingOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            )}
            {showReporting && (
              <Item
                title="Reporting"
                to="/ticketsystem"
                icon={<DescriptionOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            )}
            {showMachineDetail && (
              <Item
                title="Machine Detail"
                to="/machineDetail"
                icon={<QueryStatsIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            )}
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;

import { tokens } from "../theme";

import { useTheme, Button } from "@mui/material";

const ButtonComponent = ({
  label,
  onClick,
  customStyles = {},
  disabled,
  startIcon,
  type = "default",
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const GetBackgroundColor = () => {
    if (type === "default") return colors.blueAccent[700];
    else if (type === "warning") return colors.redAccent[500];
    else if (type === "action") return colors.greenAccent[600];
  };

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={onClick}
      startIcon={startIcon}
      sx={{
        backgroundColor: GetBackgroundColor(),
        color: colors.grey[100],
        fontSize: "12px",
        ...customStyles,
      }}
      disabled={disabled}
    >
      {label}
    </Button>
  );
};

export default ButtonComponent;

import { tokens } from "../theme";
import React, { useEffect } from "react";
import { useTheme } from "@mui/material";
import { RichTreeView } from "@mui/x-tree-view/RichTreeView";
import { useTreeViewApiRef } from "@mui/x-tree-view/hooks";

function getItemDescendantsIds(item) {
  const ids = [];
  item.children?.forEach((child) => {
    ids.push(child.id);
    ids.push(...getItemDescendantsIds(child));
  });

  return ids;
}

const getParentIds = (data, itemId) => {
  var parentIds = "";

  data.map((item) => {
    if (item.children) {
      item.children.map((child) => {
        if (child.id === itemId) {
          parentIds = item.id;
          return parentIds;
        }
      });
    }
  });

  return parentIds;
};

const TreeComponent = ({
  data,
  checkbox = false,
  selectedKeys = [],
  getSelectedList = {},
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selectedItems, setSelectedItems] = React.useState(selectedKeys);
  const toggledItemRef = React.useRef({});
  const apiRef = useTreeViewApiRef();

  useEffect(() => {
    if (getSelectedList) getSelectedList(selectedItems);
  }, [selectedItems]);

  useEffect(() => {
    setSelectedItems(selectedKeys);
  }, [selectedKeys]);

  const handleItemSelectionToggle = (event, itemId, isSelected) => {
    toggledItemRef.current[itemId] = isSelected;
  };

  const handleSelectedItemsChange = (event, newSelectedItems) => {
    const itemsToSelect = new Set([...newSelectedItems]);
    const itemsToUnselect = new Set();

    Object.entries(toggledItemRef.current).forEach(([itemId, isSelected]) => {
      const item = apiRef.current.getItem(itemId);

      if (isSelected) {
        // Select the parent and descendants
        const parent = getParentIds(data, itemId);
        if (parent) itemsToSelect.add(parent);
        itemsToSelect.add(itemId);
        getItemDescendantsIds(item).forEach((descendantId) =>
          itemsToSelect.add(descendantId)
        );
      } else {
        // Unselect the item and descendants
        getItemDescendantsIds(item).forEach((descendantId) =>
          itemsToUnselect.add(descendantId)
        );
        itemsToUnselect.add(itemId);

        // Check if all siblings of this item are also deselected
        const parent = getParentIds(data, itemId);
        if (parent) {
          const parentItem = apiRef.current.getItem(parent);
          const allSiblingsDeselected = parentItem.children.every(
            (sibling) => !newSelectedItems.includes(sibling.id)
          );
          if (allSiblingsDeselected) {
            itemsToUnselect.add(parent);
          }
        }
      }
    });

    const finalSelection = Array.from(itemsToSelect).filter(
      (itemId) => !itemsToUnselect.has(itemId)
    );

    setSelectedItems(finalSelection);

    toggledItemRef.current = {};
  };

  return (
    <RichTreeView
      items={data}
      checkboxSelection={checkbox}
      multiSelect={checkbox}
      apiRef={apiRef}
      selectedItems={selectedItems}
      onSelectedItemsChange={handleSelectedItemsChange}
      onItemSelectionToggle={handleItemSelectionToggle}
      sx={{
        color: `${colors.grey[100]} !important`,
        span: {
          color: `${colors.grey[100]} !important`,
        },
      }}
    />
  );
};

export default TreeComponent;

import { tokens } from "../theme";

import { Box, useTheme, Typography } from "@mui/material";
import { ResponsivePie } from "@nivo/pie";

const PieChartComponent = ({ data, label }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const transformData = (data) => {
    var newData = [];
    if (data) {
      newData = data.map((visionData) => ({
        id: visionData.name,
        label: visionData.name,
        value: visionData.value,
      }));
    }

    return newData;
  };

  return (
    <Box height="300px" width="300px" m="20px">
      <Typography
        variant="h5"
        color={colors.greenAccent[400]}
        textAlign="center"
      >
        {label}
      </Typography>
      {data && data.length > 0 ? (
        <ResponsivePie
          data={transformData(data)}
          margin={{ top: 20, right: 80, bottom: 80, left: 80 }}
          innerRadius={0.55}
          padAngle={2}
          activeOuterRadiusOffset={8}
          colors={{ scheme: "set1" }}
          borderWidth={1}
          borderColor={{
            from: "color",
            modifiers: [["darker", 0.2]],
          }}
          arcLinkLabelsSkipAngle={5}
          arcLinkLabelsTextColor={colors.grey[100]}
          arcLinkLabelsDiagonalLength={5}
          arcLinkLabelsStraightLength={5}
          arcLinkLabelsThickness={2}
          arcLinkLabelsColor={{ from: "color", modifiers: [] }}
          arcLabelsSkipAngle={5}
          arcLabelsTextColor={colors.grey[100]}
          legends={[
            {
              anchor: "bottom",
              direction: "row",
              justify: false,
              translateX: 0,
              translateY: 40,
              itemWidth: 100,
              itemHeight: 20,
              itemsSpacing: 0,
              symbolSize: 20,
              itemDirection: "left-to-right",
            },
          ]}
          theme={{
            legends: {
              text: {
                fontSize: 11,
                fill: `${colors.grey[100]}`,
                outlineWidth: 0,
                outlineColor: "transparent",
              },
            },
            tooltip: {
              container: {
                color: `${colors.grey[300]}`,
              },
            },
          }}
        />
      ) : (
        <Typography variant="h6" color={colors.grey[100]} textAlign="center">
          No Data
        </Typography>
      )}
    </Box>
  );
};

export default PieChartComponent;

const userRights = [
  {
    key: "reporting",
    label: "Reporting",
    children: [
      { key: "reporting.createticket", label: "Create Ticket" },
      { key: "reporting.updateticket", label: "Update Ticket" },
    ],
  },
  {
    key: "settings",
    label: "Settings",
    children: [
      { key: "settings.userright", label: "User Right Management" },
      { key: "settings.usermanagement", label: "User Management" },
    ],
  },
];

export default userRights;

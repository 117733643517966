import { tokens } from "../../theme.js";
import ApiService from "../../services/ApiService.js";

import { toast } from "react-toastify";

import {
  Box,
  useTheme,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { useState, useEffect, useContext } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import dayjs from "dayjs";

import { AuthContext } from "../../context/AuthContext";

const TicketForm = ({ dialogOpen, dialogCloseEvent, data, type }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const apiService = new ApiService(
    process.env.REACT_APP_MACHINE_DATA_BACKEND_API_PORT
  );

  const [errorGroup, setErrorGroup] = useState([]);
  const [errorCodes, setErrorCode] = useState([]);
  const [selectedErrorGroup, setSelectedErrorGroup] = useState("");
  const [selectedErrorCode, setSelectedErrorCode] = useState("");
  const [selectedRepairDate, setSelectedRepairDate] = useState(dayjs());
  const [selectedReportDate, setSelectedReportDate] = useState(dayjs());
  const [markAsDone, setMarkAsDone] = useState(false);
  const [overallData, setOverallData] = useState(null);
  const [machineList, setMachineList] = useState([]);
  const [selectedMachine, setSelectedMachine] = useState("");
  const { user } = useContext(AuthContext);

  ////////////////////FUNCTION///////////////////////
  // Fetch data when the component mounts
  useEffect(() => {
    setMarkAsDone(false);
    setSelectedErrorCode("");
    setSelectedErrorGroup("");
    setSelectedMachine("");
  }, [dialogOpen]);

  useEffect(() => {
    fetchErrorGroupData();
    fetchMachineList();
    setSelectedMachine("");
  }, []);

  useEffect(() => {
    if (data) {
      setSelectedReportDate(dayjs(data.LastReportTime));
      setSelectedRepairDate(dayjs(data.LastRepairTime));
      if (type !== "UPDATE") {
        setMarkAsDone(false);
        data.Action = "";
        setSelectedErrorCode("");
        setSelectedErrorGroup("");
        setSelectedMachine("");
      } else {
        setSelectedErrorCode(data.ErrorCodeName);
        setSelectedErrorGroup(data.ErrorGroupName);
        setMarkAsDone(data.MarkAsDone);
        setSelectedMachine(data.MachineId);
      }
      setOverallData(data);
    }
  }, [data]);

  useEffect(() => {
    if (selectedErrorGroup) {
      fetchErrorCodeData();
    }
  }, [selectedErrorGroup]);

  useEffect(() => {
    if (!errorCodes.find((code) => code.Name === selectedErrorCode)) {
      setSelectedErrorCode("");
    }
  }, [errorCodes]);

  const fetchMachineList = async () => {
    try {
      const apiVersion = process.env.REACT_APP_MACHINE_DATA_BACKEND_API_VERSION;
      const response = await apiService.get(`${apiVersion}/machine`);
      if (response.data) {
        // Sort machines by numeric values in the name
        const sortedMachines = response.data.sort((a, b) => {
          const extractNumber = (name) =>
            parseInt(name.match(/\d+/)?.[0] || 0, 10); // Extract numeric part
          return extractNumber(a.Name) - extractNumber(b.Name);
        });
        setMachineList(sortedMachines);
      }
    } catch (error) {
      console.error("Error fetching machine list:", error);
    }
  };

  const fetchErrorGroupData = async () => {
    try {
      const apiVersion = process.env.REACT_APP_MACHINE_DATA_BACKEND_API_VERSION;
      var response = await apiService.get(`${apiVersion}\\error-group`);

      if (
        response &&
        response.data.length > 0 &&
        response.data.some((item) => item !== null)
      ) {
        const transformedData = response.data.map((data, index) => {
          return {
            ...data,
            id: index + 1,
          };
        });

        setErrorGroup(transformedData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchErrorCodeData = async () => {
    try {
      const apiVersion = process.env.REACT_APP_MACHINE_DATA_BACKEND_API_VERSION;
      const selectedErrorGroupCode = errorGroup.find(
        (group) => group.Name === selectedErrorGroup
      )?.ErrorGroup;

      var response = await apiService.get(`${apiVersion}\\error-code`, {
        ErrorGroup: selectedErrorGroupCode,
      });

      if (
        response &&
        response.data.length > 0 &&
        response.data.some((item) => item !== null)
      ) {
        const transformedData = response.data.map((data, index) => {
          return {
            ...data,
            id: index + 1,
          };
        });

        setErrorCode(transformedData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const postErrorTicket = async (data) => {
    try {
      const apiVersion = process.env.REACT_APP_MACHINE_DATA_BACKEND_API_VERSION;
      const response = await apiService.post(
        `${apiVersion}/error-reports`,
        data
      );

      if (response.status === "success") {
        toast.success("Ticket created successfully.");
      } else {
        toast.error("Failed to create ticket. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Error submitting form. Please try again.");
    }
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    if (type === "OPEN" && !selectedMachine) {
      toast.error("Please select a machine to open a ticket.");
      return;
    }

    const formData = {
      MachineId:
        type === "OPEN" ? selectedMachine : overallData?.MachineId || "",
      Description:
        type === "OPEN"
          ? machineList.find((machine) => machine._id === selectedMachine)
              ?.Description || ""
          : overallData?.Description || "",
      Name:
        type === "OPEN"
          ? machineList.find((machine) => machine._id === selectedMachine)
              ?.Name || ""
          : overallData?.Name || "",
      ErrorGroup:
        errorGroup.find((group) => group.Name === selectedErrorGroup)
          ?.ErrorGroup || "",
      ErrorGroupName: selectedErrorGroup,
      ErrorCode:
        errorCodes.find((code) => code.Name === selectedErrorCode)?.ErrorCode ||
        "",
      ErrorCodeName: selectedErrorCode,
      ReportBy: type === "UPDATE" ? "" : user.name,
      RepairBy: type === "UPDATE" ? user.name : "",
      Action: event.target.action.value,
      Request: event.target.request.value,
      LastReportTime: selectedReportDate.toISOString(),
      LastRepairTime: selectedRepairDate.toISOString(),
      MarkAsDone: markAsDone,
    };

    try {
      await postErrorTicket(formData);
      await dialogCloseEvent();
    } catch (error) {
      console.error("Failed to submit the form:", error);
    }
  };

  const onChangeErrorGroup = async (event) => {
    setSelectedErrorGroup(event.target.value);
  };

  return (
    <Box>
      <Dialog
        open={dialogOpen}
        onClose={dialogCloseEvent}
        PaperProps={{
          component: "form",
          onSubmit: handleFormSubmit,
        }}
        maxWidth="md"
        fullWidth="false"
      >
        <DialogTitle>
          {type === "OPEN" ? "Open Ticket" : "Update Ticket"}
        </DialogTitle>
        <DialogContent>
          <FormControl
            fullWidth
            margin="dense"
            required
            disabled={type !== "OPEN"}
          >
            <InputLabel id="machine-select-label">Select Machine</InputLabel>
            <Select
              labelId="machine-select-label"
              value={selectedMachine}
              onChange={(e) => setSelectedMachine(e.target.value)}
            >
              {machineList.map((machine) => (
                <MenuItem key={machine._id} value={machine._id}>
                  {machine.Name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: "20px",
            }}
          >
            <FormControl margin="dense" sx={{ width: "40%" }} required>
              <InputLabel id="error-group-label">Error Group</InputLabel>
              <Select
                labelId="error-group-label"
                id="errorGroup"
                value={selectedErrorGroup}
                onChange={onChangeErrorGroup}
                label="Error Group"
                required
              >
                {errorGroup.map((error) => (
                  <MenuItem key={error.id} value={error.Name}>
                    {error.Name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl margin="dense" sx={{ width: "40%" }} required>
              <InputLabel id="error-code-label">Error Code</InputLabel>
              <Select
                labelId="error-code-label"
                id="errorCode"
                value={selectedErrorCode}
                onChange={(event) => setSelectedErrorCode(event.target.value)}
                label="Error Code"
                required
                disabled={
                  selectedErrorGroup === "" || selectedErrorGroup === null
                }
              >
                {errorCodes.map((error) => (
                  <MenuItem key={error.id} value={error.Name}>
                    {error.Name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: "20px",
            }}
          >
            <TextField
              autoFocus
              margin="dense"
              id="request"
              name="request"
              label="Request"
              fullWidth
              variant="standard"
              defaultValue={data ? data.Request : ""}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: "20px",
            }}
          >
            <TextField
              autoFocus
              margin="dense"
              id="action"
              name="action"
              label="Action"
              variant="standard"
              defaultValue={data ? data.Action : ""}
              fullWidth
              disabled={type !== "UPDATE"}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: "40px",
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={markAsDone}
                  onChange={(e) => setMarkAsDone(e.target.checked)}
                  color="primary"
                  disabled={type !== "UPDATE"}
                />
              }
              label="Mark as done"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={dialogCloseEvent}>Cancel</Button>
          <Button type="submit">Submit</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default TicketForm;
